import React from "react";
import "./releases.css";
import mainLogo from "../../assets/releases_1_FredWesley.png";

const Releases = () => {
  return (
    <div className="releases_page">
      <div className="releases_1_wrapper">
        <div className="releases_1_text">
          <h2>FRED WESLEY & THE NEW JB'S LIVE IN BELGRADE</h2>
          <br />
          <p className="releases_1_p">
            predstavlja prvo izdanje Bluzika Recordsa. Album se sastoji od 14
            funk/jazz kompozicija. <br /> <br /> Snimljen je 1. jula 2023. na
            koncertu Fred Wesleya sa svojim bandom The New JBs u klubu Bluz i
            Pivo.
          </p>
        </div>
        <img className="releases_1_img" src={mainLogo} alt="" />
      </div>
    </div>
  );
};

export default Releases;
