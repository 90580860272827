import React from "react";
import loup_garou from "../../assets/Loup-Garou.webp";
import "./news.css";

const News = () => {
  return (
    <div className="news_page">
      <p className="about_p_main about_p_newspage">
        <span className="h2_novosti">
          DRUGO IZDANJE BLUZIKA RECORDSA: “REFLECTIONS”
        </span>
        <br />
        <br />
        <img className="novosti_main_img" src={loup_garou} alt="" />
        <br />
        <br />
        Reflections je drugi, studijski album benda Loup Garou. Loup Garou je
        alternativni bluz bend iz Beograda, koji je kombinacijom swamp bluza,
        roka, fanka i svinga razvio sopstvenu verziju bluza. Ovaj bend čini
        fantastična petorka: <br />
        <br />
        Vladan Petrović (vokal i gitara)
        <br />
        Ivan Jovanović (usna harmonika) <br />
        Stefan Marković (bubnjevi) <br />
        Vladimir Nedić (gitara) <br />
        Milutin Kažić (bas)
        <br /> <br />
        Svojim originalnim tekstovima koji se bave problemima običnog čoveka
        (ljubav, bespuće, borba, razočaranja) i autentičnim zvukom, članovi
        benda oživljavaju duh močvara Luizijane. U subotu, 23. Decembra, u klubu
        Bluz i Pivo, održaće se koncert koji će biti promocija novog albuma.
      </p>
    </div>
  );
};

export default News;
