import React from "react";
import "./gallery.css";

const Gallery = ({ toggleMenu }) => {
  return (
    <div className="gallery_page">
      <div className="gallery_picture_wrapper">
        <h1>Fred Wesley & THE NEW JB'S</h1>
        <br />
        <img
          className="gallery_picture"
          alt="fred wesley concert"
          src={require("../../assets/pictures/bip_1.webp")}
        ></img>
      </div>
      <br />
      <p className="gallery_p">
        Fred Wesley- trombon i vokal <br />
        Bruce Cox- bubnjevi <br />
        Dwayne Dolphin- bas <br />
        gitara Vincent Evans- klavijature
        <br />
        Reginald Ward- gitara
        <br />
        Jay Rodriguez- saksofon i flauta
        <br />
        Gary Winters- truba
        <br />
        <br />
        <span className="gallery_p_span">Fred Wesley</span> je svoju karijeru
        započeo kao tinejdžer trombonista sa Tinom Tarner. Kasnije je postao
        kompozitor, aranžer i šef benda The JB's, pratećeg benda Jamesa Browna.
        U saradnji sa Geogre Clintonom i Bootsy Collinsom, odigrao je ključnu
        ulogu u definisanju i podizanju funka na viši nivo. Radio je sa brojnim
        muzičarima (Ray Charles, Van Morrison, Аsher, SOS band, Cameo), kao i sa
        svojim bliskim prijateljima i kolagama Maceo Parkerom i Pee Wee Ellisom.
        Danas svira i snima sa svojim sopstvenim jazz-funk bendom, The New JB-s,
        i deli svoje bogato muzičko znanje sa mladim muzičarima i studentima
        muzike u obrazovnim ustanovama širom sveta.
      </p>
    </div>
  );
};

export default Gallery;
