import React from "react";
import "./about.css";

const About = () => {
  return (
    <div className="about_page">
      <h2>O nama</h2>
      <br />
      <p className="about_p">
        <span>Bluzika Records</span>, je izdavačka kuća nastala 2023. u srcu
        Beograda. Ona je oaza istinske bluz, jazz i fank muzike, stvorena s
        ljubavlju prema ovoj vrsti muzike. Naša misija je jednostavna, ali
        duboko ukorenjena: graditi mostove između istinskih ljubitelja muzike i
        izvođača čija magija osvaja scene. Naša priča počinje u klubu Bluz i
        Pivo, gde snimamo live koncerte s posebnom pažnjom. Uhvatili smo duh
        svakog nastupa i odlučili ga pretvoriti u nešto što će trajati zauvek -
        live albume koji prenose autentičnost i energiju trenutka. <br /> <br />{" "}
        Želimo vam pružiti priliku da doživite te nezaboravne trenutke, da
        budete deo te magije. Naša posvećenost podršci lokalnoj sceni autorske
        jazz, bluz i fank muzike čini nas ključnim igračem u stvaranju prostora
        gde umetnici mogu zasijati u punom sjaju. Naš klub postaje njihova
        pozornica, a naša izdavačka kuća platforma za promociju. <br /> <br />{" "}
        Kroz redovne koncerte u klubu Bluz i Pivo, zabeležavamo ne samo zvuk,
        već i dušu grada. Uz pomoć magije live nastupa, pružamo umetnicima
        čvrstu platformu za promociju, a publici priliku da se potpuno uroni u
        energiju nezaboravnih i autentičnih nastupa. Bluzika Records je
        zajednica ljubitelja muzike koja želi biti deo priče koja će zauvek
        oblikovati beogradsku muzičku scenu. <br /> <br />
        Pratite nas na ovoj nezaboravnoj muzičkoj avanturi, budite deo svakog
        koncerta i live izdanja, jer zajedno stvaramo priču koja će odzvanjati
        kroz vreme i prostor.
      </p>
    </div>
  );
};

export default About;
