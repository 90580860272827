import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="social_icons_wrapper">
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.youtube.com/channel/UCX_DfQD0k-368rmwZpK7Xzw/videos"
        >
          <YouTube className="icons" />
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.instagram.com/bluzikarecords/"
        >
          <Instagram className="icons" color="#FFF" />
        </a>
        <a
          target={"_blank"}
          rel="noreferrer"
          href="https://www.facebook.com/bluzipivo?locale=sr_RS"
        >
          <Facebook className="icons" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className="vecteezy_hidden"
          href="https://www.vecteezy.com/free-vector/toggle-switch"
        >
          Toggle Switch Vectors by Vecteezy
        </a>
      </div>

      <p className="p_footer">© Bluzika Records 2023</p>
    </footer>
  );
};

export default Footer;
