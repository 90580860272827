import React from "react";
import { useNavigate } from "react-router";
import toggleSwitch from "../../assets/switch_off.png";
import "./header.css";

const Header = ({ currentPage, toggleMenu, setToggleMenu }) => {
  const navigate = useNavigate();
  return (
    <div className={`header_wrapper`}>
      <header className={`${toggleMenu ? "position_fixed" : ""}`}>
        <nav className={`${toggleMenu ? "nav_mobile" : ""}`}>
          <div className={`inputs_amp_wrapper ${toggleMenu ? "hidden" : ""}`}>
            <div>
              <img
                className="inputs_amp_nav"
                src={require("../../assets/inputs_final.png")}
                alt=""
              />
            </div>

            <span className="inputs_p">Input</span>
          </div>

          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>
            <button
              className={`nav_button ${
                currentPage === "/onama" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/onama");
                setToggleMenu(false);
              }}
            >
              O nama
            </button>
          </div>
          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>

            <button
              className={`nav_button ${
                currentPage === "/izdanja" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/izdanja");
                setToggleMenu(false);
              }}
            >
              Izdanja
            </button>
          </div>

          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>

            <button
              className={`nav_button ${
                currentPage === "/izvodjaci" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/izvodjaci");
                setToggleMenu(false);
              }}
            >
              Izvođači
            </button>
          </div>

          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>

            <button
              className={`nav_button ${
                currentPage === "/novosti" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/novosti");
                setToggleMenu(false);
              }}
            >
              Novosti
            </button>
          </div>

          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>

            <button
              className={`nav_button ${
                currentPage === "/radio" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/radio");
                setToggleMenu(false);
              }}
            >
              Radio
            </button>
          </div>

          <div className="button_wrapper_nav">
            <img
              className={`amp_knob_nav`}
              alt="fred wesley concert"
              src={require("../../assets/amp_knob.png")}
            ></img>
            <button
              className={`nav_button ${
                currentPage === "/kontakt" && "current_page_red"
              }`}
              onClick={() => {
                navigate("/kontakt");
                setToggleMenu(false);
              }}
            >
              Kontakt
            </button>
          </div>
        </nav>
        <div className="logo_background">
          <div
            onClick={() => {
              navigate("/");
              setToggleMenu(false);
            }}
            className="bluzika_logo"
          ></div>
        </div>

        <div
          className="toggle_switch_wrapper"
          onClick={() => setToggleMenu((prev) => !prev)}
        >
          <img
            className={`toggle_switch_nav ${toggleMenu && "toggle_switch_ON"}`}
            src={toggleSwitch}
            alt=""
          />
          <span className="inputs_p">Meni</span>
        </div>
      </header>
    </div>
  );
};

export default Header;
