import React, { lazy, Suspense } from "react";
import Loader from "../../components/Loader/Loader";
import mainLogo from "../../assets/releases_1_FredWesley.png";
import loup_garou from "../../assets/Loup-Garou.webp";
import "./mainpage.css";
import { useNavigate } from "react-router";
const ImageCarousel = lazy(() =>
  import("../../components/ImageCarousel/ImageCarousel")
);

const MainPage = () => {
  const navigate = useNavigate();
  return (
    <div className="main_page">
      <Suspense fallback={<Loader />}>
        <ImageCarousel />
      </Suspense>
      <div className="h2_redirect_button__wrapper">
        <h2>Novosti</h2>
      </div>
      <br />
      <p className="about_p_main">
        <span className="h2_novosti">
          DRUGO IZDANJE BLUZIKA RECORDSA: “REFLECTIONS”
        </span>
        <br />
        <br />
        <img className="novosti_main_img" src={loup_garou} alt="" />
        <br />
        <br />
        Reflections je drugi, studijski album benda Loup Garou. Loup Garou je
        alternativni bluz bend iz Beograda, koji je kombinacijom swamp bluza,
        roka, fanka i svinga razvio sopstvenu verziju bluza. Ovaj bend čini
        fantastična petorka: <br />
        <br />
        Vladan Petrović (vokal i gitara)
        <br />
        Ivan Jovanović (usna harmonika) <br />
        Stefan Marković (bubnjevi) <br />
        Vladimir Nedić (gitara) <br />
        Milutin Kažić (bas)
        <br /> <br />
        Svojim originalnim tekstovima koji se bave problemima običnog čoveka
        (ljubav, bespuće, borba, razočaranja) i autentičnim zvukom, članovi
        benda oživljavaju duh močvara Luizijane.
        <br />
        <br />U subotu, 23. Decembra, u klubu Bluz i Pivo, održaće se koncert
        koji će biti promocija novog albuma.
      </p>

      <div className="h2_wrapper ">
        <h2>Izdanja</h2>
      </div>
      <br />
      <div className="releases_1_wrapper_main">
        <div className="releases_1_text_main">
          <h2>FRED WESLEY & THE NEW JB'S LIVE IN BELGRADE</h2>
          <br />
          <p className="releases_1_p">
            predstavlja prvo izdanje Bluzika Recordsa. Album se sastoji od 14
            funk/jazz kompozicija. <br /> <br /> Snimljen je 1. jula 2023. na
            koncertu Fred Wesleya sa svojim bandom The New JBs u klubu Bluz i
            Pivo.
          </p>
        </div>
        <img className="releases_1_img" src={mainLogo} alt="" />
      </div>
      <br />
      <div className="h2_redirect_button__wrapper">
        <h2>O nama</h2>

        <button
          className="redirect_button"
          onClick={() => {
            navigate("/onama");
            document.documentElement.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          Pročitaj više...
        </button>
      </div>
      <br />
      <p className="about_p_main">
        Bluzika Records, je izdavačka kuća nastala 2023. u srcu Beograda. Ona je
        oaza istinske bluz, jazz i fank muzike, stvorena s ljubavlju prema ovoj
        vrsti muzike. Naša misija je jednostavna, ali duboko ukorenjena: graditi
        mostove između istinskih ljubitelja muzike i izvođača čija magija osvaja
        scene. Naša priča počinje u klubu Bluz i Pivo, gde snimamo live koncerte
        s posebnom pažnjom. Uhvatili smo duh svakog nastupa i odlučili ga
        pretvoriti u nešto što će trajati zauvek - live albume koji prenose
        autentičnost...
      </p>
    </div>
  );
};

export default MainPage;
