import MainPage from "./pages/MainPage/MainPage";
import About from "./pages/About/About";
import Gallery from "./pages/Gallery/Gallery";
import Releases from "./pages/Releases/Releases";
import News from "./pages/News/News";
import Radio from "./pages/Radio/Radio";
import Contact from "./pages/Contact/Contact";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import "./App.css";

function App() {
  const [currentPage, setCurrentPage] = useState("/");
  const [toggleMenu, setToggleMenu] = useState(false);
  let location = useLocation();
  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);
  return (
    <>
      <Header
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />

      {!toggleMenu && (
        <Routes>
          <Route
            path="/"
            element={<MainPage key={Date.now()} currentPage={currentPage} />}
          />
          <Route path="/onama" element={<About currentPage={currentPage} />} />
          <Route
            path="/izvodjaci"
            element={
              <Gallery toggleMenu={toggleMenu} currentPage={currentPage} />
            }
          />
          <Route
            path="/izdanja"
            element={<Releases currentPage={currentPage} />}
          />
          <Route path="/novosti" element={<News currentPage={currentPage} />} />
          <Route path="/radio" element={<Radio currentPage={currentPage} />} />
          <Route
            path="/kontakt"
            element={
              <Contact toggleMenu={toggleMenu} currentPage={currentPage} />
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
      <Footer />
    </>
  );
}

export default App;
