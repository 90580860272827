import React from "react";
import "./radio.css";

const Radio = () => {
  return (
    <div className="radio_page">
      <iframe
        title="radio_player"
        id="embed_player"
        frameborder="0"
        src="https://bluzikarecords.airtime.pro/embed/player?stream=auto&skin=2"
      ></iframe>
    </div>
  );
};

export default Radio;
