import React from "react";
import { Facebook, Instagram, YouTube } from "@mui/icons-material";
import "./contact.css";

const Contact = ({ toggleMenu }) => {
  return (
    <div className="contact_page">
      <div className="sections_wrapper">
        <section className="contact_section__contact">
          <h1>Kontakt</h1>
          <span>Email: bluzikarecords@gmail.com</span>
          <span>Telefon: +381 63 1773750</span>
          <span>Adresa: Cetinjska 15</span>
        </section>
        <section className="social_section__contact">
          <div className="social_icons_wrapper__contact">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.youtube.com/channel/UCX_DfQD0k-368rmwZpK7Xzw/videos"
            >
              <YouTube sx={{ fontSize: 30 }} className="icons__contact" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/bluzikarecords/"
            >
              <Instagram
                sx={{ fontSize: 30 }}
                className="icons__contact"
                color="#FFF"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/bluzipivo?locale=sr_RS"
            >
              <Facebook sx={{ fontSize: 30 }} className="icons__contact" />
            </a>
          </div>
        </section>
      </div>
      <div className={`google_map_wrapper ${toggleMenu ? "hidden" : ""}`}>
        <iframe
          className="google_map"
          title="Google Map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2514.584702006894!2d20.464343230355382!3d44.81808845778121!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7bef55f6cf85%3A0xba8eba92a1a358fa!2sBluz%20i%20Pivo!5e0!3m2!1ssr!2srs!4v1699179526697!5m2!1ssr!2srs"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;
